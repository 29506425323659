import Toast from '@comp/Toast';
import { Router } from 'next/router';
import { detectBrowser } from './browser';
import { defaultL } from './resource';
import { COUNTRY } from '@const/index';
import { getPureArticleTitle } from './helpCenter';

export function reduceToObject(
  arr: { [key: string]: any }[],
  key: string,
  val?: string,
) {
  return (arr || []).reduce((res, item) => {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      let value =
        val && Object.prototype.hasOwnProperty.call(item, val)
          ? item[val]
          : item;
      if (val && typeof item[val] === 'string') {
        try {
          value = JSON.parse(value);
        } catch (_a) {
          // continue regardless of error
        }
      }
      return Object.assign(Object.assign({}, res), { [item[key]]: value });
    } else {
      return res;
    }
  }, {});
}

/**
 * 配置文案解析，适用于带有参数的文案
 * 匹配规则：{{xxx}}
 */
interface IParams {
  [propName: string]: string;
}

export const parseString = (string: string, params?: IParams) => {
  if (params && Object.keys(params).length > 0) {
    let parsedString = string;
    const execReg = /{{([a-zA-Z]*)}}/g;
    try {
      let execArr: any = [];
      while ((execArr = execReg.exec(string)) !== null) {
        const reg = new RegExp(execArr[0], 'g');
        const key = execArr[1];
        if (typeof params[key] === 'string') {
          parsedString = parsedString.replace(reg, params[key]);
        }
      }
      return parsedString;
    } catch (e) {
      return '';
    }
  } else {
    return string;
  }
};

export const formatRouterPath = (path: string) => {
  return path;
  // 这里的代码先不删了，先保留着，防止后续再有改动
  // try {
  //   if (
  //     path &&
  //     path !== '/' &&
  //     !path.includes('mailto:') &&
  //     !path.includes('tel:')
  //   ) {
  //     const currentEnv = process.env.NODE_ENV;
  //     return currentEnv === 'development' ? path : `${path}.html`;
  //   } else {
  //     return path;
  //   }
  // } catch {
  //   return '';
  // }
};

export const isType = (value: any, type: string) =>
  Object.prototype.toString.call(value) === `[object ${type}]`;

export function preventDefaultEvent(e: Event): void {
  e.preventDefault();
}

export function disableMobileScrolling() {
  document.body.addEventListener('touchmove', preventDefaultEvent, {
    passive: false,
  });
}

export function enableMobileScrolling(): void {
  document.body.removeEventListener('touchmove', preventDefaultEvent);
}

export function getDeepLink() {
  const env = process.env.SHOPEE_ENVIRONMENT || 'live';
  const urlSuffixMap: { [key: string]: string } = {
    ph: 'ph',
    id: 'co.id',
  };

  const urlSuffix = urlSuffixMap[COUNTRY || ''] || urlSuffixMap.id;

  return env.includes('live')
    ? `https://app.seabank.${urlSuffix}/app/main`
    : `https://app-uat1.uat.seabank.${urlSuffix}/app/main`;
}

export function handleClickMDLink(
  event: any,
  pageTip?: { [key: string]: string },
  defaultTip = '',
) {
  const target = event.target as HTMLLinkElement;
  const browserInfo = detectBrowser();
  if (target?.nodeName.toLocaleLowerCase() === 'a') {
    let url = target.getAttribute('href');
    if (!url) return;
    if (url.startsWith('native:')) {
      event.preventDefault();
      if (browserInfo.isPc) {
        // TODO: 等待PM提供新文案
        Toast.show(
          pageTip?.[url] ||
            defaultTip ||
            (defaultL === 'id'
              ? 'Mohon lakukan verifikasi wajah pada aplikasi SeaBank'
              : 'Please continue via facial verification in the SeaBank app'),
        );
        return;
      }
      url = getDeepLink();
    }

    if (target.getAttribute('target') === '_blank') {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }
}

export const getReplaceParams = (
  route: Router,
  props: PageTmpl | LeftNavPageTemplate | ProductTmpl,
):
  | {
      [name: string]: string;
    }
  | undefined => {
  switch (route.pathname) {
    case '/help-center/article/[title]': {
      const paths = route.asPath.split('/');
      return {
        title: getPureArticleTitle(decodeURIComponent(paths[3])) || '',
      };
    }
    case '/help-center/[[...category]]': {
      const paths = route.asPath.split('/');
      let name = '';
      let subName = '';
      let categoryName = '';

      if (paths.length === 4) {
        subName = decodeURIComponent(paths.pop() || '');
        name = decodeURIComponent(paths.pop() || '');
        categoryName = getPureArticleTitle(subName);
      } else if (paths.length === 3) {
        name = decodeURIComponent(paths.pop() || '');
        categoryName = getPureArticleTitle(name);
      }

      return {
        name,
        subName,
        categoryName,
        path: route.asPath,
      };
    }
    case '/branch-product':
      return {
        title: 'Branch Product',
      };
    case '/info':
      return {
        title: 'Info',
      };
    case '/branch-product/[tab]':
    case '/info/[tab]': {
      const path = route.query.tab as string;
      let pathName = 'Info';
      if (path) {
        const tabs = (props as LeftNavPageTemplate).tabBars.reduce(
          (tabs: LeftNavPageTabBarItem[], tabBar) => [...tabs, ...tabBar.tabs],
          [],
        );
        pathName = tabs.find((tab) => tab.path === path)?.name || '';
      }
      return {
        path: path || 'Info',
        pathName,
      };
    }
    case '/product/[subpage]': {
      const subpage = route.query.subpage as string;
      const pages = (props as ProductTmpl).subpages;
      const currentPage =
        pages.find((page) => page.path === subpage) || pages[0];
      return {
        product: currentPage.name,
        subpage,
      };
    }
  }
};

export const getPageInfo = (
  route: Router,
  props: PageTmpl | LeftNavPageTemplate,
) => {
  const defaultTitle = 'SeaBank';
  const defaultDesc = '';
  const breadcrumb = [...(props.breadcrumb || [])];
  const replaceParam = getReplaceParams(route, props);

  let path = route.asPath;
  if (breadcrumb.length) {
    path = breadcrumb[breadcrumb?.length - 1]?.path || path;
  }

  if (replaceParam) {
    return {
      title: parseString(props.title, replaceParam) || defaultTitle,
      desc: parseString(props.description, replaceParam) || defaultDesc,
      path: parseString(path, replaceParam),
    };
  }

  return {
    title: props.title || defaultTitle,
    desc: props.description || defaultDesc,
    path,
  };
};

export const mesureHeight = (
  text: string,
  containerStyle: Partial<CSSStyleDeclaration>,
) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.left = '-9999px';
  div.style.top = '-9999px';
  div.style.width = containerStyle.width || '';
  div.style.fontFamily = containerStyle.fontFamily || '';
  div.style.fontSize = containerStyle.fontSize || '';
  div.style.lineHeight = containerStyle.lineHeight || '';
  div.innerText = text;
  document.body.appendChild(div);
  const height = div.offsetHeight;
  document.body.removeChild(div);
  return height;
};
