import LogoSvg from '@/assets/svg/logov1.svg';
import SGLogoSvg from '@/assets/svg/logo-sg.svg';

type CountryType = typeof process.env.COUNTRY;
export const COUNTRY = (process.env.SHOPEE_COUNTRY ?? 'id') as CountryType;

export const TIME_ZONE = 'Asia/Manila';

export const AppName = ({
  id: 'SeaBank',
  ph: 'SeaBank',
  sg: 'MariBank',
} as Record<string, string>)[COUNTRY];

export const TitleTail = ({
  id: `${AppName} ID`,
  ph: `${AppName} PH`,
  sg: `${AppName} SG`,
} as Record<string, string>)[COUNTRY];

export const ENV = process.env.SHOPEE_ENVIRONMENT || 'live';

export const Logo = ({
  id: LogoSvg,
  ph: LogoSvg,
  sg: SGLogoSvg,
} as Record<string, typeof LogoSvg>)[COUNTRY];

type EnvType = 'dev' | 'test' | 'sit' | 'uat' | 'staging' | 'live';
const shopeeEnv = process.env.SHOPEE_ENVIRONMENT;

const ceph: Record<CountryType, string> = {
  sg:
    shopeeEnv === 'live'
      ? 'ceph-core.bke.shopee.io'
      : `corebank-ceph-${shopeeEnv}.mdw.seabanksvc.com`,
  ph:
    shopeeEnv === 'live'
      ? 'ceph.mdw.seabanksvc.com'
      : 'ceph-nonlive.mdw.seabanksvc.com',
  id:
    shopeeEnv === 'live'
      ? 'ceph-core.bke.shopee.io'
      : 'ceph-core-nonlive.bke.shopee.io',
};

const loanCeph: Record<CountryType, string> = {
  sg:
    shopeeEnv === 'live'
      ? 'ceph-loan.mdw.seabanksvc.com'
      : `corebank-ceph-${shopeeEnv}.mdw.seabanksvc.com`,
  ph:
    shopeeEnv === 'live'
      ? 'ceph.mdw.seabanksvc.com'
      : 'ceph-nonlive.mdw.seabanksvc.com',
  id:
    shopeeEnv === 'live'
      ? 'ceph-loan.mdw.seabanksvc.com'
      : 'ceph-core-nonlive.bke.shopee.io',
};

/**
 * to select value according to country
 * @param localeMap a map contains key in {@link CountryType}
 * @returns
 */
export const localeSelect: <T>(
  localeMap: { [locale in CountryType]?: T },
) => T | undefined = (localeMap) => {
  return localeMap[COUNTRY];
};

/**
 * to select value according to environment
 * @param envMap a map contains key in {@link EnvType}
 * @returns
 */
export const envSelect: <T>(
  envMap: { [env in EnvType]: T } & { default: T },
) => T | undefined = (envMap) => {
  if (shopeeEnv.includes('live')) {
    return envMap.live;
  }

  if (shopeeEnv.includes('uat')) {
    return envMap.uat;
  } else if (shopeeEnv.includes('sit')) {
    return envMap.test;
  } else if (shopeeEnv.includes('dev')) {
    return envMap.dev;
  } else if (shopeeEnv.includes('test')) {
    return envMap.dev;
  } else if (shopeeEnv.includes('staging')) {
    return envMap.staging;
  }

  return envMap.default;
};

export const Local = {
  country: COUNTRY,
  ceph: ceph[COUNTRY],
  loanCeph: loanCeph[COUNTRY],
};

export const DOM_ID = {
  toastId: 'toast',
};
