/*
 * @Author: lijianling
 * @Date: 2022-08-09 16:04:34
 * @LastEditors: Cheng Zhao
 * @LastEditTime: 2022-12-08 14:55:37
 * @Description: utils of help-center
 */
import {
  HOT_PATH_NAME,
  HOT_QUESTIONS_CATEGORY_ID,
  ID_REG,
  ROOT_PATH,
} from '@/constant/helpCenter';
import { getDetails, preloadArticles } from '@/services/helpCenter';
import { NextRouter } from 'next/router';
import { formatRouterPath } from './misc';

export const getPureArticleTitle = (pathname: string) =>
  pathname.replace(/^(\d+)-/, '');

export const getArticlePath = (pathname: string) => {
  return formatRouterPath(
    `${ROOT_PATH}/article/${encodeURIComponent(pathname)}`,
  );
};

export const getCategoryPath = (
  category?: Category,
  subCategory?: Category,
) => {
  if (!category) {
    return ROOT_PATH;
  }
  if (subCategory) {
    return formatRouterPath(
      `${ROOT_PATH}/${category.categoryId}-${encodeURIComponent(
        category.categoryName,
      )}/${subCategory.categoryId}-${encodeURIComponent(
        subCategory.categoryName,
      )}`,
    );
  }
  return formatRouterPath(
    `${ROOT_PATH}/${category.categoryId}-${encodeURIComponent(
      category.categoryName,
    )}`,
  );
};

export const flatCategory = (
  nodes?: Category[],
  result: Category[] = [],
): Category[] => {
  nodes?.forEach((node) => {
    result.push(node);
    flatCategory(node?.children, result);
  });

  return result;
};

export type CategoryMapType = Record<number, Category & { parentId: number }>;

export const flatCategoryTreeToObj = (
  nodes: Category[],
  result: CategoryMapType = {},
  parent?: Category,
) => {
  if (!Array.isArray(nodes) || !nodes?.length) {
    return result;
  }

  nodes.forEach((item) => {
    result[item.categoryId] = { ...item, parentId: parent?.categoryId || 0 };

    if (item.children) {
      flatCategoryTreeToObj(item.children, result, item);
    }
  });

  return result;
};

export const forEachCategory = (
  list: Category[],
  fn: (category: Category) => any,
): Category[] => {
  const ret = [...list];
  ret.forEach((parent) => {
    fn(parent);
    parent.children?.forEach(fn);
  });
  return ret;
};

export const findCategory = ({
  id,
  list,
}: {
  id?: number;
  list: Category[];
}) => {
  const array = flatCategory(list);

  return array.find((v) => v.categoryId === id);
};

export const getCategoryByRoute = (router: NextRouter, list: Category[]) => {
  let category = null;
  let subCategory = null;
  const paths = router.asPath.split('?')[0].split('/');
  const len = paths.length;

  // /help-center/hot-questions不带id前缀，特殊处理
  if (len === 3 && paths[len - 1] === HOT_PATH_NAME) {
    return {
      category: findCategory({ id: HOT_QUESTIONS_CATEGORY_ID, list }),
      subCategory,
    };
  }

  if (len === 3) {
    const match = paths[len - 1]?.match(ID_REG);
    if (match) {
      category = findCategory({ id: +match[0], list });
    }
  } else if (len === 4) {
    let match = paths[len - 1]?.match(ID_REG);
    if (match) {
      subCategory = findCategory({
        id: +match[0],
        list,
      });
    }
    match = paths[len - 2]?.match(ID_REG);
    if (match) {
      category = findCategory({ id: +match[0], list });
    }

    if (
      !category ||
      !subCategory ||
      !category.children?.includes(subCategory)
    ) {
      category = null;
      subCategory = null;
    }
  }

  return {
    category,
    subCategory,
  };
};

export const getFAQs = async (ids: number[]) => {
  const result: question[] = [];

  while (result.length < ids.length) {
    const rsp: question[] = await getDetails(ids.slice(result.length));
    // 若接口返回空，不继续调用，避免死循环
    if (!rsp?.length) {
      break;
    }
    result.push(...rsp);
  }

  return result.filter((v) => v); // 如果id不存在，接口返回的数组会包含null，需将其移除
};

export const preLoadFAQs = async ({
  categoryIds,
  pageSize,
}: {
  categoryIds: number[];
  pageSize: number;
}) => {
  const result: Category[] = [];

  const rsp = await preloadArticles({
    categoryIds: categoryIds.slice(result.length),
    pageSize,
  });
  // 若接口返回空，不继续调用，避免死循环

  const ret: Record<
    number,
    {
      total: number;
      data: question[];
    }
  > = {};
  if (!Array.isArray(rsp)) {
    return ret;
  }

  rsp.forEach((item) => {
    if (!item) return;
    ret[item.categoryId] = {
      total: item.data?.total || 0,
      data: item.data?.data || [],
    };
  });

  return ret;
};

export const getCategoryFullPathByCategoryId = (
  categoryId: number,
  categoryMap: CategoryMapType,
) => {
  let resultPath = '';
  let categoryItem = categoryMap[categoryId];

  while (categoryItem) {
    resultPath = `/${categoryItem.categoryId}-${encodeURIComponent(
      categoryItem.categoryName,
    )}${resultPath}`;

    categoryItem = categoryMap[categoryItem.parentId];
  }

  return resultPath;
};
