/*
 * @Author: kaikai.huang
 * @Date: 2022-09-20 11:19:48
 * @LastEditors: kaikai.huang
 * @LastEditTime: 2023-01-09 18:59:09
 * @Description:
 */

import { ENV } from '@const/index';

// uat, staging & live has cdn, cannot support post request
// should use ori host instead
const oriHosts: Record<string, string> = {
  uat1: 'https://www-ori-uat1.uat.seabank.ph',
  uat2: 'https://www-ori-uat2.uat.seabank.ph',
  staging: 'https://www-ori.staging.seabank.ph',
  stagingdr: 'https://www-ori.stagingdr.seabank.ph',
  live: 'https://www-ori.seabank.ph',
};

export const ApiHost = oriHosts[ENV] || '';
