/*
 * @Author: lijianling
 * @Date: 2022-08-09 16:04:44
 * @LastEditors: Cheng Zhao
 * @LastEditTime: 2022-12-08 15:13:27
 * @Description: help-center相关常量
 */
export const LANDING_PATH_NAME = '/help-center/[[...category]]';
export const HOT_PATH_NAME = 'hot-questions';

export const ROOT_PATH = '/help-center';
export const HOT_PATH = `${ROOT_PATH}/${HOT_PATH_NAME}`;
export const SEARCH_PATH = `${ROOT_PATH}/search`;

export const LEGACY_FAQ_PATH_STR = '^\\/help-center\\/details\\/(\\d{3})$';

export const HOT_QUESTIONS_CATEGORY_ID = -1;
export const MIN_SEARCH_VALUE_LEN = 3;

export const DOMAIN_MARK = '{{domain}}';
export const LINK_MARK = '{{helpCenterLink}}';
export const ID_REG = /\d+/;
